// Import pages
import Dashboard from '../../Pages/Dashboard';
import Finances from '../../Pages/Finances';
import Locations from '../../Pages/Locations';

// import OrganizationDetail from '../../Pages/OrganizationDetail';
import Profile from '../../Pages/Profile';
// import Settings from '../../Pages/Settings';
import Importer from '../../Pages/Importer';
import Setup from '../Setup';

import { TransactionsPage } from '../../transactions';

// Billing
import Invoices from '../../Pages/Invoices';
import InvoicePlans from '../../Pages/InvoicePlans';
import InvoicePlanEdit from '../../Pages/InvoicePlanEdit';
import InvoiceReports from '../../Pages/InvoiceReports';

import { DetailsContainer } from '../Forms/Billing/Create';
import { SelectEmployeesContainer } from '../Forms/Billing/SelectEmployees';
import { ConfirmContainer } from '../Forms/Billing/Confirm';

const routes = [
  {
    to: '/',
    name: 'Dashboard',
    exact: true,
    enabled: true,
    hasMenu: true,
    component: Dashboard,
    permission: '*',
  },
  // {
  //   to: '/students',
  //   name: 'Students',
  //   exact: true,
  //   enabled: true,
  //   hasChildren: true,
  //   hasMenu: true,
  //   component: Students,
  //   permission: 'can_list_students',
  // },
  // {
  //   to: '/staff',
  //   name: 'Staff',
  //   exact: true,
  //   enabled: true,
  //   hasMenu: true,
  //   restrictedRoute: true,
  //   component: Staff,
  //   permission: 'can_list_staff',
  // },
  // {
  //   to: '/forms-and-documents',
  //   name: 'Forms & Documents',
  //   exact: true,
  //   enabled: false,
  //   hasMenu: true,
  //   restrictedRoute: true,
  //   component: FormsAndDocuments,
  //   permission: 'can_list_forms',
  // },
  // {
  //   name: 'Reports',
  //   exact: true,
  //   enabled: true,
  //   hasChildren: true,
  //   hasMenu: true,
  //   permission: 'can_list_students',
  //   menuGroup: 'reports',
  //   childrenMenu: [
  //     {
  //       to: '/attendance',
  //       name: 'Attendance',
  //       exact: true,
  //       enabled: true,
  //       hasMenu: true,
  //       restrictedRoute: true,
  //       component: Attendance,
  //       permission: 'can_list_students',
  //       menuGroup: 'students',
  //     },
  //   ]
  // },
  // {
  //   to: '/billing/external',
  //   name: 'Billing',
  //   exact: true,
  //   enabled: true,
  //   hasMenu: true,
  //   restrictedRoute: true,
  //   component: () => {
  //     const redirectHash = Buffer.from(
  //       JSON.stringify({
  //         ret: `//${process.env.REACT_APP_WS_BILLING_APP_URL}/auth-return`,
  //         rts: {},
  //       })
  //     ).toString('base64');
  //     window.location = `${process.env.REACT_APP_WS_AUTH_URL}/#${redirectHash}`;
  //   },
  //   permission: '*',
  // },
  // Finances related routes.
  {
    name: 'Finances',
    exact: true,
    enabled: true,
    hasChildren: true,
    hasMenu: true,
    restrictedRoute: true,
    permission: 'can_list_billing',
    menuGroup: 'finances',
    childrenMenu: [
      {
        to: '/billing/invoices',
        name: 'Invoices',
        exact: true,
        enabled: true,
        hasMenu: true,
        restrictedRoute: true,
        component: Invoices,
        permission: 'can_list_billing',
        menuGroup: 'finances',
      },
      {
        to: '/billing/invoice',
        name: 'DetailsContainer',
        exact: true,
        enabled: true,
        hasMenu: false,
        restrictedRoute: true,
        component: DetailsContainer,
        permission: 'can_edit_billing',
        menuGroup: 'finances',
      },
      {
        to: '/billing/invoices/edit',
        name: 'Invoices',
        exact: true,
        enabled: true,
        hasMenu: false,
        restrictedRoute: true,
        component: DetailsContainer,
        permission: 'can_edit_billing',
        menuGroup: 'finances',
      },
      {
        to: '/billing/invoice/select',
        name: 'SelectEmployeesContainer',
        exact: true,
        enabled: true,
        hasMenu: false,
        restrictedRoute: true,
        component: SelectEmployeesContainer,
        permission: 'can_list_billing',
        menuGroup: 'billing',
      },
      {
        to: '/billing/invoice/confirm',
        name: 'ConfirmContainer',
        exact: true,
        enabled: true,
        hasMenu: false,
        restrictedRoute: true,
        component: ConfirmContainer,
        permission: 'can_list_billing',
        menuGroup: 'billing',
      },
      {
        to: '/billing/plans',
        name: 'Recurring Plans',
        exact: true,
        enabled: true,
        hasMenu: true,
        restrictedRoute: true,
        component: InvoicePlans,
        permission: 'can_list_billing',
        menuGroup: 'finances',
      },
      {
        to: '/billing/plans/edit',
        name: 'Invoice Plan Editor',
        exact: true,
        enabled: true,
        hasMenu: false,
        restrictedRoute: true,
        component: InvoicePlanEdit,
        permission: 'can_edit_billing',
        menuGroup: 'finances',
      },
      {
        to: '/billing/invoices/reports',
        name: 'Aging Reports',
        exact: true,
        enabled: true,
        hasMenu: true,
        restrictedRoute: true,
        component: InvoiceReports,
        permission: 'can_list_billing',
        menuGroup: 'finances',
      },
      {
        to: '/transactions',
        name: 'Transactions',
        exact: true,
        enabled: true,
        hasMenu: true,
        restrictedRoute: true,
        component: TransactionsPage,
        permission: 'can_list_billing',
      },
    ],
  },

  // My Website external related routes.
  // {
  //   name: 'My Website',
  //   exact: true,
  //   enabled: true,
  //   hasChildren: true,
  //   hasMenu: true,
  //   restrictedRoute: true,
  //   marketplace: true,
  //   permission: 'can_list_marketplace_links',
  //   menuGroup: 'myWebsite',
  //   childrenMenu: [
  //     {
  //       to: '/marketplace/marketplace-school-id/website',
  //       name: 'Website Content',
  //       exact: true,
  //       enabled: true,
  //       hasMenu: true,
  //       restrictedRoute: true,
  //       marketplace: true,
  //       component: () => {},
  //       permission: 'can_list_marketplace_links',
  //       menuGroup: 'myWebsite',
  //     },
  //     {
  //       to: '/marketplace/marketplace-school-id/interested',
  //       name: 'Interested Families',
  //       exact: true,
  //       enabled: true,
  //       hasMenu: true,
  //       restrictedRoute: true,
  //       marketplace: true,
  //       component: () => {},
  //       permission: 'can_list_marketplace_links',
  //       menuGroup: 'myWebsite',
  //     },
  //     {
  //       to: '/marketplace/marketplace-school-id/interested/calendar',
  //       name: 'Tours',
  //       exact: true,
  //       enabled: true,
  //       hasMenu: true,
  //       restrictedRoute: true,
  //       marketplace: true,
  //       component: () => {},
  //       permission: 'can_list_marketplace_links',
  //       menuGroup: 'myWebsite',
  //     },
  //   ],
  // },

  // // Help related routes.
  // {
  //   to: '/marketplace/marketplace-school-id/resources',
  //   name: 'Help/Resource Hub',
  //   exact: true,
  //   enabled: true,
  //   hasMenu: true,
  //   restrictedRoute: true,
  //   marketplace: true,
  //   component: () => {},
  //   permission: 'can_list_marketplace_links',
  // },

  // Settings related routes.
  {
    name: 'Settings',
    exact: true,
    enabled: true,
    hasChildren: true,
    hasMenu: true,
    restrictedRoute: true,
    component: Locations,
    permission: 'can_list_locations',
    menuGroup: 'settings',
    childrenMenu: [
      // {
      //   to: '/settings/locations',
      //   name: 'Location details',
      //   exact: true,
      //   enabled: true,
      //   hasMenu: true,
      //   restrictedRoute: true,
      //   component: Locations,
      //   permission: 'can_list_locations',
      //   hidefor: 'familyChildCare',
      // },
      // {
      //   to: '/settings/rooms',
      //   name: 'Rooms',
      //   exact: true,
      //   enabled: true,
      //   hasMenu: true,
      //   restrictedRoute: true,
      //   component: Rooms,
      //   permission: 'can_list_rooms',
      //   hidefor: 'familyChildCare',
      // },
      // {
      //   to: '/settings/devices',
      //   name: 'Shared Devices',
      //   exact: true,
      //   enabled: true,
      //   hasMenu: true,
      //   restrictedRoute: true,
      //   component: Devices,
      //   permission: 'can_list_devices',
      //   hidefor: 'familyChildCare',
      // },
      {
        to: '/settings/finances',
        name: 'Finances',
        exact: true,
        enabled: true,
        hasMenu: true,
        restrictedRoute: true,
        component: Finances,
        permission: 'can_list_finance',
        hidefor: 'familyChildCare',
      },
    ],
  },

  // Admin related routes.
  // {
  //   name: 'Admin',
  //   exact: true,
  //   enabled: true,
  //   hasMenu: true,
  //   restrictedRoute: true,
  //   component: Admin,
  //   permission: 'can_list_moxit_organizations',
  //   hasChildren: true,
  //   menuGroup: 'admin',
  //   childrenMenu: [
  //     {
  //       to: '/admin/providers',
  //       name: 'Providers',
  //       exact: true,
  //       enabled: true,
  //       hasMenu: true,
  //       restrictedRoute: true,
  //       component: Admin,
  //       permission: 'can_list_moxit_organizations',
  //     },
  //     {
  //       to: '/admin/users',
  //       name: 'Users',
  //       exact: true,
  //       enabled: true,
  //       hasMenu: true,
  //       restrictedRoute: true,
  //       component: Admin,
  //       permission: 'can_list_moxit_organizations',
  //     },
  //     {
  //       to: '/admin/networks',
  //       name: 'Networks',
  //       exact: true,
  //       enabled: true,
  //       hasMenu: true,
  //       restrictedRoute: true,
  //       component: Admin,
  //       permission: 'can_list_moxit_organizations',
  //     },
  //     {
  //       to: '/admin/area-51',
  //       name: 'Area 51 👽',
  //       exact: true,
  //       enabled: true,
  //       hasMenu: true,
  //       restrictedRoute: true,
  //       component: Admin,
  //       permission: 'can_list_moxit_organizations',
  //     },
  //     {
  //       to: '/forms',
  //       name: 'Forms Builder',
  //       exact: true,
  //       enabled: true,
  //       hasMenu: true,
  //       restrictedRoute: true,
  //       component: () =>
  //         (window.location = process.env.REACT_APP_WS_FORMS_APP_URL),
  //       permission: 'can_list_moxit_organizations',
  //     },
  //   ],
  // },

  {
    to: '/profile',
    name: 'Profile',
    exact: true,
    enabled: true,
    hasMenu: false,
    restrictedRoute: true,
    component: Profile,
    permission: '*',
  },
  {
    to: '/setup',
    name: 'Setup',
    exact: true,
    enabled: true,
    hasMenu: false,
    restrictedRoute: true,
    component: Setup,
    permission: 'can_setup_organization',
  },
  {
    to: '/import/:type',
    name: 'Setup',
    exact: false,
    enabled: true,
    hasMenu: false,
    restrictedRoute: true,
    component: Importer,
    permission: 'can_edit_import',
  },
];

export default routes;
