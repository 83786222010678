import React from 'react';
import { Message } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

function ShowWarning ({ iconName = 'warning', header, content }){
  const { t } = useTranslation();
  const _header = header || t('Warning!');

  return (content) ? (
    <Message warning icon={iconName} header={_header} content={content} />
  ) : null;
}

export default ShowWarning;
