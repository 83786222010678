import { isEmpty } from 'lodash';
import { firebaseConfig, wsConfig } from '../api/firebase/config';

const ENV_KEY = 'envName';

const LOCAL = 'local';
const DEV = 'dev';
const PROD = 'prod';

/**
 * Tries to detect the environment from config
 * @returns {'local' | 'dev' | 'prod' | undefined}
 */
const getEnvFromConfig = () => {
  return wsConfig?.[ENV_KEY] || undefined;
};

/**
 * Tries to determine from process env variables.
 * Finally, assumes production.
 * @returns {'local' | 'dev' | 'prod' | undefined}
 */
const detectEnv = () => {
  let env;
  if (process.env.NODE_ENV === 'development') {
    // try to detect from process.env
    env = DEV;
    if (process.env.REACT_APP_FIREBASE_EMULATOR === 'ACTIVE') {
      env = LOCAL;
    }
  } else {
    // default to prod
    env = PROD;
  }
  return env;
};

let savedEnv;
/**
 * Either uses a memoized env value,
 * the config-specified env value,
 * or the detected env value
 * @returns {'local' | 'dev' | 'prod' | undefined}
 * If not a proper env, fails fast and loud and throws an error
 */
const getEnv = () => {
  const wasEnvSet = !!savedEnv;
  savedEnv = savedEnv || getEnvFromConfig() || detectEnv();
  if (!wasEnvSet) {
    if (!isTest()) {
      if (isLocal()) {
        console.log('💻 LOCAL environment 💻');
      } else if (isDev()) {
        console.log('🧪 DEV environment 🧪');
      }
    }
  }
  if (
    !savedEnv ||
    (savedEnv !== LOCAL && savedEnv !== DEV && savedEnv !== PROD)
  ) {
    throw new Error('Invalid env');
  }
  return savedEnv;
};

export const getTitlePrefix = (env = getEnv()) => {
  if (isLocal()) {
    return '💻 ';
  } else if (isDev()) {
    return '🧪 ';
  } else {
    return '';
  }
};

/**
 * gets the hosted domain name for this app
 * @returns {string}
 */
const getDomainName = () => {
  return {
    [LOCAL]: '//localhost:3100',
    [DEV]: process.env.REACT_APP_WS_CCMS_APP_URL,
    [PROD]: process.env.REACT_APP_WS_CCMS_APP_URL,
  }[getEnv()];
};

const getConfig = () => {
  return {
    firebaseConfig,
    wsConfig: {
      ...wsConfig,
      domainName: getDomainName(),
    },
  };
};

/**
 * @param {string} env env name
 * @returns {boolean}
 */
const isLocal = (env = getEnv()) => env === LOCAL;
/**
 * @param {string} env env name
 * @returns {boolean}
 */
const isDev = (env = getEnv()) => env === DEV;
/**
 * @param {string} env env name
 * @returns {boolean}
 */
const isProd = (env = getEnv()) => env === PROD;
/**
 * @param {string} env env name
 * @returns {boolean}
 */
const isTest = (env = getEnv()) =>
  (isLocal(env) && isEmpty(process.env.REACT_APP_GIT_SHA)) ||
  !!process.env.REACT_APP_IS_TEST;

// this logger needs to be independent of rollbar logger to prevent ciruclar reference
const setupLogger = (...args) => {
  if (isLocal() && !isTest()) {
    console.log(...args);
  }
};

export {
  getEnv,
  getConfig,
  getDomainName,
  isLocal,
  isDev,
  isProd,
  isTest,
  setupLogger,
};
